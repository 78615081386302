import React from "react";

const videos = [
  {
    url: "https://www.youtube.com/embed/8H239YAkjCk", 
  },
  {
    url: "https://jaslopezportfolio.s3.us-west-1.amazonaws.com/AliyahsInterlude/ITGIRLREMIXBTS.mp4",
    poster: 'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/Thumbnails/aliyahthumb.png',
  },
  {
    url: "https://jaslopezportfolio.s3.us-west-1.amazonaws.com/TeezoVideo/FINAL+CUT+TEEZO+BTS.mp4",
    poster: 'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/Thumbnails/teezothumb.png', 
  },
  {
    url: "https://www.youtube.com/embed/OVhGRuDEVGY?si=LW7f9l0H_qUoxzIt",
  },
];

const VideoPage = () => {
  return (
    <div className="container mx-auto py-8 px-4 sm:px-6 md:px-0">
      <div className="flex flex-col items-center space-y-6">
        {videos.map((video, index) => (
          <div key={index} className="relative w-full max-w-[640px] h-[360px]"> {/* Fixed width and height */}
            {video.url.includes('youtube.com') ? (
              <iframe
                src={video.url}
                title={`Video ${index + 1}`}
                className="absolute top-0 left-0 w-full h-full"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                referrerPolicy="strict-origin-when-cross-origin"
                loading="lazy"
              ></iframe>
            ) : (
              <video
                controls
                className="w-full h-full object-cover"
                poster={video.poster || ""}
              >
                <source src={video.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            <div className="p-4">
              {/* Add any additional content or styling here */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoPage;
