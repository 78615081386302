import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar';
import FooterNavbar from './components/footerNavbar';
import Footer from './components/footer';
import VideoPage from './assets/pages/Videos';
import AboutMe from './assets/pages/AboutMe';
import Archive from './assets/pages/Archive';
import Photos from './assets/pages/Photos';
import HomeGallery from './assets/pages/HomeGallery';
import NotFound from './assets/pages/NotFound';
import './App.css';
import './input.css';

function App() {
  // const isMobileView = window.innerWidth <= 768;

  return (
    <Router>
      <div className="App">
        <Navbar />
   
        <Routes>
        <Route path="/" element={<HomeGallery/>} />
          <Route path="/photo" element={<Photos />} />
          <Route path="/photo" element={<Photos />} />
          <Route path="/video" element={<VideoPage />} /> 
          <Route path="/archive" element={<Archive />} /> 
          <Route path="/about" element={<AboutMe />} /> 
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>

    // {isMobileView ? <FooterNavbar /> : null} !>
  );
}

export default App;
