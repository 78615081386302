import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faVimeo } from '@fortawesome/free-brands-svg-icons';
import logo from '../assets/photography/logo-index.svg'

const Footer = () => {
  return (
    <footer className="bg-gray-300 text-black py-8">

      <div className="container mx-auto flex flex-col items-center justify-center">
      <p className="text-xs sm:text-sm font-medium text-black ">
          Los Angeles, CA
        </p>
        <img
          src={logo}
          alt="footer_logo"
          className="w-16 sm:w-20 md:w-24 mb-2"
        />
        <p className="text-xs sm:text-sm font-medium text-center">
          Privacy Policy | <a href="mailto:jasminelopezvieyra@gmail.com" className=" hover:text-lime-800 ">© {new Date().getFullYear()} Jasmine Lopez Vieyra</a>
        </p>

        <p className="text-xs sm:text-sm font-medium text-center mt-2 mb-5">
          <a
            href="mailto:jasminelopezvieyra@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-4 hover:text-lime-800"
          >
            jasminelopezvieyra@gmail.com
          </a></p>

          <div className="flex justify-center text-xs sm:text-sm mb-4">
                <a href="https://www.instagram.com/jas.lo" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} className="w-6 h-6 mx-2 text-lime-950" />
                </a>
                <a href="https://vimeo.com/user215090412" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faVimeo} className="w-6 h-6 mx-2 text-lime-950" />
                </a>
                <a href="mailto:jasminelopezvieyra@gmail.com" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faEnvelope} className="w-6 h-6 mx-2 text-lime-950" />
                </a>
            </div>


        <p className="text-xs sm:text-sm font-medium text-center mt-4 mb-20 md:mb-5">
          Created by{" "}
          <a
            href="https://almaalvarado.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
            className=" hover:text-lime-800"
          >
            Alma Alvarado
          </a>
        </p>
      
      </div>
    </footer>
  );
};

export default Footer;
