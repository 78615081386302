import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faVimeo } from '@fortawesome/free-brands-svg-icons';
import aboutmeImg from '../photography/aboutme-pic.jpg';

const AboutMe = () => {
  return (
    <section id="about-me" className="py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-center">
          {/* Image Section */}
          <div className="md:w-1/3 mb-4 md:mb-0 md:mr-12">
            <img
              src={aboutmeImg}
              alt="Jasmine Lopez"
              className="shadow-lg h-auto w-48 mx-auto md:mx-0 md:w-auto"
            />
          </div>
          {/* Text Section */}
          <div className="md:w-2/3 md:pl-8">
            <p className="text-md mt-5">
            Born and Raised in Los Angeles, CA Jasmine Lopez Vieyra is a Latina artist who began photography as a teenager and professionally in 2018. Since then, she has expanded to vhs videography and assisting on and off sets. Her style of work predominantly focuses on documenting milestone events, behind the scenes moments and creative directing personal projects. Her creative approach stems from her upbringing, where access to her own history was scarce, Jasmine is motivated to preserve visual stories. 

            </p>
            <h2 className="text-sm font-bold mb-2 mt-16 text-gray-600">Follow My Work</h2>
            <div>
                <a href="https://www.instagram.com/jas.lo" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} className="w-6 h-6 mx-2 text-lime-950" />
                </a>
                <a href="https://vimeo.com/user215090412" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faVimeo} className="w-6 h-6 mx-2 text-lime-950" />
                </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
